import React from 'react'

import imagemSeta from '../../srcFiles/img/planos/Vector22.svg';

function SingleTable() {
  return (
    <section className='planoSingle' >

        <div className='plano'>
            <div className='heading'>
                <h1>
                    <b>Plano<br/></b>
                    básico
                </h1>
            </div>
            <div className="infoplano">
                <ul>
                    <li>Inscrições online de Atletas</li>
                    <li>Pagamentos de mensalidades Online</li>
                    <li>Emails automáticos com referencia de pagamentos</li>
                </ul>
            </div>
        </div>

        <div className='preco'>
            
            <h1>
                <svg width="154" height="1" viewBox="0 0 154 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line y1="0.5" x2="154" y2="0.5" stroke="black"/>
                </svg>

                14.99€ MÊS
            </h1>
        </div>
        
        <div className='vetorSeta'>
           <img src={imagemSeta} alt="seta_vermelha"/>
           <img src={imagemSeta} alt="seta_vermelha"/>
        </div>
        
        <div className='absoluteBarra'></div>
    </section>
  )
}

export default SingleTable