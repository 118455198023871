import React from 'react'

function MobileMenu() {

    const fecharPop = () => {
        document.getElementById('iconMobile').classList.toggle('active');
        document.getElementById('popupMenu').classList.toggle('active');
    }

  return (
    <div className='popupMenu' id="popupMenu">
        <div className='container'>
            <ul>
                <li>
                    <a href="#projeto" onClick={fecharPop}>O Projeto</a>
                </li>

                <li>
                    <a href="#tabela" onClick={fecharPop}>Planos</a>
                </li>

                <li>
                    <a href="#testemunhos" onClick={fecharPop}>Testemunhos</a>
                </li>

                <li>
                    <a href="#form" onClick={fecharPop}>Formulário</a>
                </li>

                <li>
                    <a href="%PUBLIC_URL%/login">Login</a>
                </li>
            
            </ul>
        </div>
    </div>
  )
}

export default MobileMenu