
import './srcFiles/css/style.css';
import Header from './Components/Header/Header';
import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

// Images
import imagePC from './srcFiles/img/hero/pc.webp';
import importancia from './srcFiles/img/Importancia/campanha.webp';
import telePais from './srcFiles/img/Importancia/telePais.webp';
import Laptop from './srcFiles/img/Pontos/Laptop.webp';
import Video from './srcFiles/video/desktop.mp4';
import VideoWebm from './srcFiles/video/desktop.webm';
import lottieTele from './srcFiles/lotties/tele';
import imagemFundo from './srcFiles/img/hero/shapeFundo.svg'
import PrincingTable from './Components/PricingTables/PricingTable';
import ContactForm from './Components/ContactForm/ContactForm';
import MobileMenu from './Components/MobileMenu/MobileMenu';
import SingleTable from './Components/PricingTables/SingleTable';

function App() {

  const containerLottie = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: containerLottie.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: lottieTele
    });
  }, []);


  return (
    <>

      <Header/>

      <section className='heroSection'>
        <img src={imagemFundo} id="fundoAbs" alt='imagem de fundo' />
        <div className="container">
          <div className="hero">
            <h1>Software de gestão da formação do seu clube</h1>
            <a href="#form" className="btn">
              Contacte-nos
            </a>
          </div>
          <div className="imageContainer">
            <img src={imagePC} alt="imagemPC" />
          </div>
        </div>
      </section>


      <section className='importanciaDiv' id="projeto">
        <div className="leftText">
          <h1><b>A importância da</b> MacroFoot no seu clube</h1>
          <p>
            Todos os clubes, necessitam de uma organização controlada, prática e de fácil análise.
            Reúna todas as informações (atletas e encarregados de educação) de todos os escalões de formação, devidamente organizados, o que facilita a admnistração e coordenadores do clube na hora da inscrição das equipas e atletas.
          </p>
          <a href="#form">Adquirir</a>
        </div>
        <div className="rightImg">
          <img src={importancia} className="" alt="" />
        </div>
      </section>


      {/* avisar os "pais" */}
      <section className='avisarPais'>

        <div className="rightImg" >
          <img src={telePais} className="" alt="imagem Telemovel" />
        </div>

        <div className="leftText">
          <h1><b>A partir de agora, não vai necessitar </b>de avisar os "pais" dos atletas.</h1>
          <p>
            Através dos contactos previamente recolhidos, com o nosso software consegue enviar para os educandos, notificações prévias (5 dias antes) de aviso a solicitar o pagamento juntamente com a respetiva referência bancária (com validade de 5 dias), para que os mesmos procedam ao pagamento da mensalidade definida pelo clube, a tempo e horas.</p>
        </div>

      </section>


      {/* formação dor de cabeça */}
      <section className='formacao'>

        <div className="leftText">
          <h1><b>A formação vai deixar de ser uma dor de cabeça!</b>
            <br />
            Aceda a todas as informações no seu perfil
          </h1>
          <p>
            Na renovação e aquisição de novo material desportivo por parte dos atletas, com a MacroFoot consegue fazer a
            gestão de pagamentos do material encomendado, através do envio automático de referências. O responsável no
            clube apenas necessita de definir os preços e selecionar o material que o educando encomendou.
          </p>
          <a href="#form">Subscrever</a>
        </div>

        <div className="rightImg" ref={containerLottie}>
        </div>

      </section>


      {/*  O educando */}
      <section className='educando'>

        <div className="rightImg">
          <img src={Laptop} alt="imagem Portátil" className="" />
        </div>

        <div className="leftText">
          <h1>
            O educando não procedeu ao pagamento no tempo útil da referência?
            <b> Não se preocupe!</b>
          </h1>

          <p>
            Com a MacroFoot pode gerar novas referências e enviar novamente para o educando,
            mantendo assim uma gestão completamente organizada da formação do seu clube.
          </p>

          <div className='newText'>
            <h1>
              <mark>Economize tempo e trabalho</mark>
            </h1>

            <p>
              A MacroFoot disponibiliza-lhe as ferramentas necessárias para gerir a formação do seu
              clube de forma rápida, simples e eficaz. Esqueça os longos e interminavéis papéis para
              os atletas e encarregados preencherem, a partir de agora tem todos os dados necessários
              devidamente guardados e organizados no nosso Software.
            </p>

          </div>
        </div>
      </section>


      {/*  O educando */}
      <section className='educandoNew'>
        <div className="container">
          <div className="rightImg">
            <img src={Laptop} alt="imagem Portátil" className="" />
          </div>

          <div className="leftText">
            <h1>
              O educando não procedeu ao pagamento no tempo útil da referência?
              <b> Não se preocupe!</b>
            </h1>

            <p>
              Com a MacroFoot pode gerar novas referências e enviar novamente para o educando,
              mantendo assim uma gestão completamente organizada da formação do seu clube.
            </p>
          </div>
        </div>

        <div className='barraMark'>

          <div className='heading'>
            <h1>
              Economize tempo e trabalho
            </h1>
          </div>
          <p>
            A MacroFoot disponibiliza-lhe as ferramentas necessárias para gerir a formação do seu
            clube de forma rápida, simples e eficaz. Esqueça os longos e interminavéis papéis para
            os atletas e encarregados preencherem, a partir de agora tem todos os dados necessários
            devidamente guardados e organizados no nosso Software.
          </p>

        </div>
      </section>


      {/* Pontos Chave */}
      <section className='PontosChave'>
        <div className='container'>

          <div className='leftHeading'>
            <h1>Pontos chave</h1>
            <h4>da MacroFoot</h4>
          </div>

          <div className='rightPontos'>
            <ul>
              <li>
                <p>Facilidade na inscrição de atletas </p>
              </li>
              <li>
                <p>Base de dados dos atletas e encarregados de educação </p>
              </li>
              <li>
                <p>Controlar o fluxo de cobranças em escalões de formação </p>
              </li>
              <li>
                <p>Alertas de pagamento  </p>
              </li>
              <li>
                <p>Envio de referências multibanco </p>
              </li>
              <li>
                <p>Organização dos atletas por escalão</p>
              </li>
            </ul>
          </div>
        </div>

      </section>


      {/* Video */}
      <section className='VideoSection'>
        <video autoPlay loop muted playsInline>
          <source src={VideoWebm} type="video/webm" />
          <source src={Video} type="video/mp4" />
        </video>
      </section>


      {/* <PrincingTable /> */}
      <SingleTable />


      <ContactForm />

      <MobileMenu />

    </>
  );
}

export default App;
