import React from 'react'
import logoMacrofoot from '../../srcFiles/img/logos/logoMacrofoot.svg'

function Header() {

    const changeIcon = () => {
        document.getElementById('iconMobile').classList.toggle('active');
        document.getElementById('popupMenu').classList.toggle('active');
    }


    // onscroll 300px down header will have a bg color
    const changeColor = () => {
        if (window.scrollY > 300) {
            document.getElementById('menuHeader').classList.add('bg-black');
        } else {
            document.getElementById('menuHeader').classList.remove('bg-black');
        }
    }

    window.addEventListener('scroll', changeColor);

  return (
    <header className='headerMenu' id="menuHeader">
        <div className="container">
            
            <div className="logo">
                <img src={logoMacrofoot} alt="Logotipo MacroFoot"  />
            </div>

            <div className="menu">
                <ul>
                    <li>
                        <a href="#projeto">O Projeto</a>
                    </li>

                    <li>
                        <a href="#tabela">Planos</a>
                    </li>

                    <li>
                        <a href="#testemunhos">Testemunhos</a>
                    </li>

                    <li>
                        <a href="#form">Formulário</a>
                    </li>

                    <li>
                        <a href="%PUBLIC_URL%/login">Login</a>
                    </li>
                
                </ul>
            </div>

            <div className='mobileMenu'>
                <div className='mobileMenuIcon' id="iconMobile" onClick={changeIcon}>
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                </div>
            </div>

        </div>
    </header>
  )
}

export default Header