import React,{useState} from 'react'


function ContactForm() {


    const [formInputs, setFormInputs] = useState({
        nome: '',
        email: '',
        clube: '',
        telemovel: ''
    });

    const [erros, setErros] = useState({
        nome: '',
        email: '',
        clube: '',
        telemovel: ''
    });

     // Variável Global de Erros
     const errors = {};


    // Este handleChange é para quando o utilizador alterar o valor do input e o valor é guardado no state do formInputs
    const handleChange = (e) => {
        setFormInputs({
            ...formInputs,
            [e.target.name]: e.target.value,
        });

    //    check if the input nome has a value
        if (formInputs.nome !== '') {
            handleNomeChange();
        }

        if(formInputs.email !== ''){
            handleEmailChange();
        }

        if(formInputs.clube !== ''){
            handleClubeChange();
        }

    
    }

    const handleNomeChange = () => {

        const nomeInput = document.getElementById('inputNome');
        const nomePattern = /^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/;

        if (!formInputs.nome.match(nomePattern) ) {
            nomeInput.style.border = '1px solid red';
        }
        else{
            nomeInput.style.border = 'none';
        }
    
    }

    const handleEmailChange = () => {

        const emailInput = document.getElementById('inputEmail');
        const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

        if (!formInputs.email.match(emailPattern)) {
            emailInput.style.border = '1px solid red';
        }
        else{
            emailInput.style.border = 'none';
        }
    
    }

    const handleClubeChange = () => {

        const clube = document.getElementById('inputClube');

    
        if (formInputs.clube === '' || formInputs.clube === null || formInputs.clube === undefined || formInputs.clube.length < 3) {
            clube.style.border = '1px solid red';
        }
        else{
            clube.style.border = 'none';
        }

    }


   
    // Este handleSubmit é para quando o utilizador submeter o formulário
    const handleSubmit = (e) => {
        const form = document.getElementById('form');
        e.preventDefault(); 
        console.log(formInputs);
    
        validateName(); // Valida o nome
        
        validateEmail(); // Valida o email

        validateClube(); // Valida o clube

        validateTelemovel(); // Valida o telemovel

        setErros(errors); // Guarda os erros no state do erros
        console.log(errors) // Mostra os erros no console

        // Se não houver erros, envia o formulário
        if (Object.keys(errors).length === 0) {
            console.log('Form submitted');
            form.submit();
        }
        else{
            console.log('Form not submitted'); // Se houver erros, não envia o formulário
        }

    }


    // Função que valida se o nome está bem inserido.
    const validateName = () => {

        const nomeInput = document.getElementById('inputNome');
        const nomePattern = /^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/;

        if (formInputs.nome === '' || formInputs.nome === null || formInputs.nome === undefined || !formInputs.nome.match(nomePattern)) {
            errors.nome = 'Please enter your nome';
            nomeInput.style.border = '1px solid red';
        }
        else{
            nomeInput.style.border = 'none';
        }
       
    }

    // Função que valida se o email está bem inserido.
    const validateEmail = () => {

        const emailInput = document.getElementById('inputEmail');
        const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;


        if (formInputs.email === '' || formInputs.email === null || formInputs.email === undefined || !formInputs.email.match(emailPattern)) { 
            errors.email = 'Please enter your email';
            emailInput.style.border = '1px solid red';
        }
        else{
            emailInput.style.border = "none";
        }
      
    }

    // Função que valida se o Clube está bem inserido.
    const validateClube = () => {

        const clube = document.getElementById('inputClube');

        if (formInputs.clube === '') {
            errors.clube = 'Please enter your clube';
            clube.style.border = '1px solid red';

        }
        else{
            clube.style.border = 'none';
        }
      
    }

    // Função que valida se o Telemovel está bem inserido.
    const validateTelemovel = () => {

        const telemovel = document.getElementById('inputTelemovel');

        if (formInputs.telemovel === '' || formInputs.telemovel === null || formInputs.telemovel === undefined) {
            errors.telemovel = 'Please enter your telemovel';
            telemovel.style.border = '1px solid red';
        }
        else{
            telemovel.style.border = 'none';
        }
  
    }


  return (
    <section className='contactForm' >

        <div className="splitLeft">
            <h4>Contacte</h4>
            <p>e agende uma demonstração</p>
        </div>

        <div className="splitRight">

            {/* Formulário */}
            <form id="form" method='post' action='sendmail.php'>

                {/* Nome */}
                <div className="form-group">
                    <label htmlFor="InputNome">Nome</label>
                    <input type="text" className="form-control" onChange={handleChange} name="nome" id="inputNome" />
                </div>

                {/* Clube */}
                <div className="form-group">
                    <label htmlFor="inputClube">Clube</label>
                    <input type="text" className="form-control" onChange={handleChange} name="clube" id="inputClube" />
                </div>

                {/* Email */}
                <div className="form-group">
                    <label htmlFor="inputEmail">Email</label>
                    <input type="email" className="form-control" onChange={handleChange}  name="email" id="inputEmail" />
                </div>

                {/* Telemovel */}
                <div className="form-group">
                    <label htmlFor="inputTelemovel">Número de Telemóvel</label>
                    <input type="tel"  pattern="[0-9]+" className="form-control" onChange={handleChange} name="telemovel" id="inputTelemovel" />


                    <input type="text" className="form-control dnone" name="bots" id="bots" />
                </div>
            
                <a onClick={handleSubmit} >Enviar</a>
            </form>

        </div>
    </section>
  )
}

export default ContactForm